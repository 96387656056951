.geo_filters_left .MuiGrid-container .MuiGrid-item {
	max-width: 25%;
	flex-basis: 25%;
}

.geo_filters_right .MuiGrid-container .dateBlk {
	max-width: 40%;
	flex-basis: 40%;
}

.geo_filters_right .MuiGrid-container .periodViewBlk {
	max-width: 20%;
	flex-basis: 20%;
}

.checkbox_icon span.Mui-checked {
	color: rgb(0, 83, 147) !important;
}

.competitor_modal {
	height: 500px;
	overflow-y: auto;
}

.Mui-disabled span {
	color: #808182 !important;
}